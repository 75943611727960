import { mapActions, mapGetters } from 'vuex'
import mainSidebar from './components/sidebar/index.vue'

export default {
  name: 'home',
  data () {
    return {
      drawer: true,
      miniVariant: false,
      loadingLogout: false
    }
  },
  components: {
    mainSidebar
  },
  created () {
    this.fetchSetting()
    if (!this.profile) {
      this.fetchUserData()
    }
  },
  computed: {
    ...mapGetters({
      translations: 'setting/translations',
      profile: 'profile/profile',
      configLoading: 'setting/configLoading',
      error: 'system/error'
    }),
    ...mapGetters([
      'languages',
      'currentLanguage'
    ])
  },
  methods: {
    ...mapActions({
      fetchSetting: 'courses/COURSES_GET_SETTING',
      fetchUserData: 'profile/FETCH_USER_DATA',
      logout: 'auth/AUTH_LOGOUT'
    }),
    removeAuthToken () {
      this.loadingLogout = true
      this.logout().finally(() => {
        this.loadingLogout = false
        this.$router.push({ name: 'sing-in' })
      })
    }
  }
}
