import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'home',
  data () {
    return {
      items: [
        {
          action: 'mdi-bookshelf',
          title: 'Courses',
          to: 'courses'
        },
        {
          action: 'mdi-home-edit',
          title: 'Home works',
          to: 'user-works-courses'
        },
        {
          action: 'mdi-certificate',
          title: 'Certificates and diplomas',
          to: 'user-certificate-courses'
        },
        {
          action: 'mdi-comment',
          title: 'Reviews',
          to: 'reviews'
        },
        {
          action: 'people',
          title: 'Users',
          active: false,
          items: [
            {
              title: 'Admins',
              to: 'user.admins'
            },
            {
              title: 'Students',
              to: 'user.students'
            },
            {
              title: 'Roles',
              to: 'user.roles'
            },
            {
              title: 'Positions',
              to: 'user.positions'
            }
          ]
        },
        {
          action: 'mdi-newspaper-variant-multiple-outline',
          title: 'News',
          active: false,
          items: [
            {
              title: 'List',
              to: 'news.list'
            },
            {
              title: 'Categories',
              to: 'news.categories'
            },
            {
              title: 'Tags',
              to: 'news.tags'
            }
          ]
        },
        {
          to: 'events.list',
          action: 'event',
          title: 'Events'
        },
        {
          action: 'assignment',
          title: 'Education materials',
          active: false,
          items: [
            {
              title: 'Action plan',
              to: 'action-plans'
            },
            {
              title: 'Webinars',
              to: 'webinars'
            },
            {
              title: 'Q/A sessions',
              to: 'qa-sessions'
            },
            {
              title: 'Master classes',
              to: 'master-classes'
            },
            {
              title: 'Bonuses',
              to: 'bonuses'
            },
            {
              title: 'Streams',
              to: 'streams'
            }

          ]
        },
        {
          action: 'mdi-ab-testing',
          title: 'Tests',
          active: false,
          items: [
            {
              title: 'List',
              to: 'tests'
            },
            {
              title: 'Results',
              to: 'tests-results'
            }
          ]
        },
        {
          action: 'mdi-cog',
          title: 'Setting',
          active: false,
          items: [
            {
              title: 'Tariffs',
              to: 'tariffs'
            },
            {
              title: 'Tariff types',
              to: 'setting.tariff-types'
            },
            {
              title: 'Education categories',
              to: 'setting.education-categories'
            },
            {
              title: 'Course types',
              to: 'setting.course-types'
            },
            {
              title: 'Course categories',
              to: 'setting.course-categories'
            },
            {
              title: 'Course skills',
              to: 'setting.course-skills'
            },
            {
              title: 'Countries',
              to: 'setting.countries'
            },

            // {
            //   title: 'Locales',
            //   to: 'setting.locales'
            // },
            {
              title: 'Layouts',
              to: 'setting.layouts'
            },
            {
              title: 'Templates',
              to: 'setting.templates'
            },
            {
              title: 'Niches',
              to: 'setting.niches'
            },
            {
              title: 'Widgets',
              to: 'setting.widgets'
            },
            {
              title: 'Store Links',
              to: 'setting.stores'
            },
            {
              title: 'Default merchant',
              to: 'setting.getaway'
            },
            {
              title: 'Merchants',
              to: 'setting.getaway.providers'
            },
          ]
        },
        {
          action: 'mdi-google-translate',
          title: 'Translations',
          active: false,
          items: [
            {
              title: 'Labels',
              to: 'setting.translations.labels'
            },
            {
              title: 'Text',
              to: 'setting.translations.text'
            }
          ]
        },
        {
          action: 'mdi-sitemap',
          title: 'Administration',
          active: false,
          items: [
            // {
            //     title: 'Menus',
            //     to: 'administration.menus'
            // },
            {
              title: 'Static pages',
              to: 'administration.pages'
            }
          ]
        },
        {
          action: 'mdi-form-select',
          title: 'Forms',
          active: false,
          items: [
            {
              title: 'List',
              to: 'forms'
            },
            {
              title: 'Feedback',
              to: 'feedbacks'
            }
          ]
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      groups: 'translations/groups'
    }),
    groupsTranslations () {
      return this.groups.map(group => ({ ...group, to: 'translations.group' }))
    },
    itemsSidebar () {
      return this.items.map(item => item.title === 'Translations' ? ({ ...item, items: [...this.groupsTranslations] }) : item)
    }
  },
  created () {
    if (localStorage.getItem('isDarkTheme')) {
      if (localStorage.getItem('isDarkTheme') === '1') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else {
      localStorage.setItem('isDarkTheme', '0')
      this.$vuetify.theme.dark = false
    }
    this.fetchGroups()
  },
  methods: {
    ...mapActions({
      fetchGroups: 'translations/GET_GROUPS'
    }),
    changeTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if (this.$vuetify.theme.dark) {
        localStorage.setItem('isDarkTheme', '1')
      } else {
        localStorage.setItem('isDarkTheme', '0')
      }
    }
  }
}
